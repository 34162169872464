'use client'
import { isNullOrEmpty } from '@/utils/string-extensions'
import { useEffect, type ReactNode } from 'react'
export const Modal = ({
  open,
  children,
  handleClose,
  className,
}: {
  children: ReactNode
  open: boolean
  handleClose?: () => void
  className?: string
}) => {
  useEffect(() => {
    if (!open && isNullOrEmpty(document.body.style.overflow)) return
    document.body.style.overflow = open ? 'hidden' : 'auto'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [open])

  if (!open) {
    return null
  }

  return (
    <div
      className='fixed inset-0 z-[9999] flex h-screen w-screen transform-gpu place-content-center bg-gray-800/40 transition-colors'
      onClick={handleClose}
    >
      <div
        className={className}
        onClick={(event) => {
          event.stopPropagation()
        }}
      >
        {children}
      </div>
    </div>
  )
}
