'use client'

import { Button } from '../library/button'
import { ErrorBoundary } from 'react-error-boundary'
import React from 'react'

const fallbackRender = (
  {
    error,
    resetErrorBoundary,
  }: {
    error: Error
    resetErrorBoundary: () => void
  }, // Call resetErrorBoundary() to reset the error boundary and retry the render.
) => (
  <div role='alert'>
    <p>Something went wrong:</p>
    <pre style={{ color: 'red' }}>{error.message}</pre>
    <Button onClick={resetErrorBoundary}>Try again</Button>
  </div>
)

export const WishListErrorBoundary = ({
  children,
}: {
  children: React.ReactNode
}) => {
  return (
    <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>
  )
}
