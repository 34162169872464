'use client'

import { getUser } from '@/lib/data/get-user'
import { sendCustomerDetailsClient } from '@/utils/gtm-events-client'
import { Hub } from 'aws-amplify/utils'
import { useEffect } from 'react'

export default function LogAuthEvents() {
  useEffect(() => {
    const hubListenerCancelToken = Hub.listen('auth', async ({ payload }) => {
      if (payload.event === 'signedIn') {
        const user = await getUser()

        if (user) {
          sendCustomerDetailsClient({
            userId: user.userId as string,
            email: user.email as string,
            phone: user.mobile as string,
            firstName: user.firstName as string,
            lastName: user.lastName as string,
            zip: user.preferences?.postCodes?.[0] ?? null,
          })
        }
      }
    })

    return () => {
      hubListenerCancelToken()
    }
  }, [])

  return null
}
