/* eslint-disable unicorn/no-useless-undefined */
'use client'
import {
  type FC,
  type PropsWithChildren,
  useCallback,
  useMemo,
  useState,
  Fragment,
} from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
  type SetUserActionErrorProps,
  type SetUserActionErrorPropsSingle,
  UserActionContext,
} from './user-action-error-context'

import { Button } from '@/components/library/button'
import { Icon } from '@/icons/icon'
import { useRouter } from 'next/navigation'

export const UserActionErrorProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [userActionError, setUserActionErrorInternal] =
    useState<SetUserActionErrorProps>()
  const router = useRouter()

  const setUserActionError = useCallback(
    ({ height = 250, width = 300, ...props }: SetUserActionErrorProps) => {
      setUserActionErrorInternal({
        height,
        width,
        ...props,
      })

      setTimeout(() => {
        if (props.navigate_to == null) {
          if (!props.disable_route_refresh) {
            router.refresh()
          }
        } else {
          router.push(props.navigate_to)
        }
      })
    },
    [router],
  )

  const closeError = useCallback(() => {
    setUserActionErrorInternal(undefined)
  }, [])

  const isErrorOpen = useMemo(
    () => userActionError !== undefined,
    [userActionError],
  )

  const Buttons = () => {
    if (userActionError == null) {
      return null
    }
    if ('buttons' in userActionError) {
      return (
        <>
          {userActionError.buttons?.map((button, index) => (
            <Button
              key={index}
              color={button.color ?? 'primary'}
              variant={button.buttonVariety ?? 'outlined'}
              size='large'
              onClick={() => {
                if (button.buttonAction != null) {
                  button.buttonAction()
                }
                closeError()
              }}
            >
              {button.buttonLabel}
            </Button>
          ))}
        </>
      )
    }

    const remainingError = userActionError as SetUserActionErrorPropsSingle

    return (
      <Button
        className='flex'
        onClick={() => {
          if (remainingError.buttonAction != null) {
            remainingError.buttonAction()
          }
          closeError()
        }}
        color={remainingError.color ?? 'primary'}
        variant={remainingError.buttonVariety ?? 'outlined'}
        size='large'
      >
        {remainingError.buttonLabel ?? 'Ok, got it'}
      </Button>
    )
  }

  return (
    <UserActionContext.Provider value={{ setUserActionError }}>
      <Transition appear show={isErrorOpen} as={Fragment}>
        <Dialog as='div' open={isErrorOpen} onClose={closeError}>
          <div className='fixed inset-0 z-10 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className='z-20 flex flex-col items-center justify-center rounded-3xl bg-white p-10 shadow-2xl'
                  style={{
                    minHeight: userActionError?.height,
                    minWidth: userActionError?.width,
                    height: userActionError?.height,
                    width: userActionError?.width,
                  }}
                >
                  <Dialog.Title>
                    <Icon
                      name='alert-regular'
                      size='l'
                      strokeWidth='1'
                      customColor='white'
                    />
                    <div className='mb-6' />
                    <div
                      className='mb-4 text-center text-lg'
                      data-testid='user-action-title'
                    >
                      {userActionError?.error_title ?? 'Error'}
                    </div>
                  </Dialog.Title>
                  <Dialog.Description
                    className='mb-8 text-center text-sm'
                    data-testid='user-action-text'
                  >
                    {userActionError?.error_message}
                  </Dialog.Description>
                  <Buttons />
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      {children}
    </UserActionContext.Provider>
  )
}
