'use client'

import { createContext, useCallback, useContext, useEffect } from 'react'

import { useSearchParams } from 'next/navigation'

interface ShopbackProviderProps {
  setTransactionId: (transactionId: string) => void
  getTransactionId: () => string | null
}

export const ShopbackContext = createContext<ShopbackProviderProps>({
  setTransactionId: () => {},
  getTransactionId: () => null,
})

export const ShopbackProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const setTransactionId = useCallback((transactionId: string) => {
    if (typeof window !== 'undefined') {
      sessionStorage.setItem('shopback_transaction_id', transactionId)
    }
  }, [])

  const getTransactionId = useCallback(
    () =>
      typeof window === 'undefined'
        ? null
        : sessionStorage.getItem('shopback_transaction_id'),
    [],
  )

  return (
    <ShopbackContext.Provider value={{ getTransactionId, setTransactionId }}>
      {children}
    </ShopbackContext.Provider>
  )
}

export const ShopbackInterceptor = () => {
  const searchParams = useSearchParams()
  const { setTransactionId } = useContext(ShopbackContext)

  useEffect(() => {
    const utmSource = searchParams.get('utm_source')
    const transactionId = searchParams.get('transaction_id')
    if (utmSource === 'shopback' && transactionId) {
      setTransactionId(transactionId)
    }
  }, [searchParams, setTransactionId])

  return null
}
