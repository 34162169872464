'use client'

import '@/lib/amplify-init'
import 'aws-amplify/auth/enable-oauth-listener'
import 'react-toastify/dist/ReactToastify.css'

import { ToastContainer, Zoom } from 'react-toastify'

export default () => {
  return (
    <ToastContainer
      autoClose={5000}
      hideProgressBar
      transition={Zoom}
      pauseOnHover
      className={'flex w-full flex-col items-center'}
      closeButton={false}
      bodyClassName={'mt-1'}
      toastClassName={'mt-1 rounded-2xl'}
      toastStyle={{
        marginTop: '1rem',
        width: 'fit-content',
        minWidth: '24rem',
        borderRadius: '0.5rem',
      }}
    />
  )
}
