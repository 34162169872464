export const POSTCODE_COOKIE_KEY = 'postcode'

export const PROMOTION_TITLE_KEY = 'promotion-title'

export const BASKET_COOKIE_KEY = 'basket'
export const PLATFORM_BASKET_COOKIE_KEY = 'platform-basket'
export const SEGMENT_COOKIE_KEY = 'hypertune-segment'

export const phoneAURegex =
  /^(?:\+?(61))? ?(?:\((?=.*\)))?(0?[2-578])\)? ?(\d\d(?:[ -](?=\d{3})|(?!\d\d[ -]?\d[ -]))\d\d[ -]?\d[ -]?\d{3})$/
