'use client'

import { type ReactNode, createContext, useContext } from 'react'

export interface UserActionError {
  __typename?: 'userActionError' | 'UserActionError'
  error_message?: string | null
  error_title?: string | null
  navigate_to?: string | null
  disable_route_refresh?: boolean | null
}

export interface ErrorButtonProps {
  buttonLabel: string
  buttonAction: () => void
  buttonVariety?: 'text' | 'outlined' | 'contained' | 'reversed'
  color?:
    | 'primary'
    | 'secondary'
    | 'accent'
    | 'dark'
    | 'error'
    | 'success'
    | 'warning'
    | 'info'
}

export type ErrorVariant = 'default' | 'primary' | 'success' | 'error'

interface UserActionWithComponent
  extends Omit<UserActionError, 'error_message'> {
  error_message?: string | ReactNode
  variant?: ErrorVariant
}

export interface SetUserActionErrorPropsSingle
  extends UserActionWithComponent,
    Partial<ErrorButtonProps> {
  height?: number
  width?: number
  buttons?: ErrorButtonProps[]
}

export interface SetUserActionErrorPropsMultiple
  extends UserActionWithComponent {
  height?: number
  width?: number
  buttons?: ErrorButtonProps[]
}

export type SetUserActionErrorProps =
  | SetUserActionErrorPropsSingle
  | SetUserActionErrorPropsMultiple

export interface UserActionErrorProps {
  setUserActionError: (error: SetUserActionErrorProps) => void
}

export const UserActionContext = createContext<UserActionErrorProps>({
  setUserActionError: () => {
    throw new Error('not ready yet')
  },
})

export const useUserActionError = () => useContext(UserActionContext)
