'use client'

import { type GetWishListsQuery } from '@/lib/generated/graphql'
import { WishlistContext } from './wishlist-provider'
import { useContext, Suspense } from 'react'
import { WishListErrorBoundary } from './wishlist-error'
import { useEffectOnce } from 'react-use'

export const WishlistFillerContainer = ({
  wishlistPromise,
}: {
  wishlistPromise: false | GetWishListsQuery
}) => {
  return (
    <Suspense>
      <WishListErrorBoundary>
        <WishListFiller wishlistPromise={wishlistPromise} />
      </WishListErrorBoundary>
    </Suspense>
  )
}

export const WishListFiller = ({
  wishlistPromise,
}: {
  wishlistPromise: false | GetWishListsQuery
}) => {
  const { setWishlist } = useContext(WishlistContext)
  const wishlists = wishlistPromise

  useEffectOnce(() => {
    if (
      wishlists !== false &&
      wishlists?.getWishLists?.__typename === 'wishLists' &&
      wishlists.getWishLists?.items?.[0] != null
    ) {
      setWishlist(wishlists.getWishLists.items[0])
    }
  })

  return <></>
}
