'use client'

import { useLogin } from '@/components/account/context/use-login'
import { sendCustomerDetailsClient } from '@/utils/gtm-events-client'
import { usePathname, useSearchParams } from 'next/navigation'
import { useEffect, useRef } from 'react'

export default function LogScreenViews() {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const { user } = useLogin()
  const prevPathname = useRef<string>('')
  const prevSearchParams = useRef<string>('')

  useEffect(() => {
    async function logEvent() {
      if (user) {
        const postCode = user.preferences?.postCodes?.[0] ?? null

        await sendCustomerDetailsClient({
          email: user.email as string,
          firstName: user.firstName as string,
          lastName: user.lastName as string,
          phone: user.mobile as string,
          userId: user.userId as string,
          zip: postCode,
        })
      }
    }

    const _searchParams = searchParams.toString()

    if (
      prevPathname.current !== pathname ||
      prevSearchParams.current !== _searchParams
    ) {
      logEvent()
      prevPathname.current = pathname
      prevSearchParams.current = _searchParams
    }
  }, [pathname, searchParams, user])

  return null
}
