import { type User } from '../generated/graphql'
import { hostURL } from '@/utils/functions/url-utils'
import * as Sentry from '@sentry/nextjs'

export const getUser = async () => {
  try {
    const res = await fetch(`${hostURL()}/api/user`)

    return (await res.json()) as User
  } catch (error) {
    // eslint-disable-next-line import/namespace
    Sentry.captureException(error)
  }
}
