export async function sha256(value: string) {
  const encodedValue = new TextEncoder().encode(value)
  const hashBuffer = await crypto.subtle.digest('SHA-256', encodedValue)

  const hexes = []
  const view = new DataView(hashBuffer)

  for (let i = 0; i < view.byteLength; i += 4)
    hexes.push(('00000000' + view.getUint32(i).toString(16)).slice(-8))

  return hexes.join('')
}
