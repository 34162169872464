import { Amplify } from 'aws-amplify'
import { hostURL } from '@/utils/functions/url-utils'
import { type ResourcesConfig } from '@aws-amplify/core'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { CookieStorage } from 'aws-amplify/utils'

export const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      allowGuestAccess: true,
      userPoolId: process.env.NEXT_PUBLIC_AWS_USER_POOL_ID as string,
      userPoolClientId: process.env
        .NEXT_PUBLIC_AWS_USER_POOL_WEB_CLIENT_ID as string,
      identityPoolId: process.env.NEXT_PUBLIC_AWS_IDENTITY_POOL_ID as string,
      loginWith: {
        oauth: {
          domain: process.env.NEXT_PUBLIC_AWS_OAUTH_DOMAIN as string,
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: [hostURL()],
          redirectSignOut: [hostURL()],
          responseType: 'code',
        },
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: process.env.NEXT_PUBLIC_GATEWAY as string,
      region: process.env.NEXT_PUBLIC_AWS_REGION as string,
      defaultAuthMode: 'lambda',
    },
    REST: {
      appsync: {
        endpoint: process.env.NEXT_PUBLIC_GATEWAY as string,
        region: process.env.NEXT_PUBLIC_AWS_REGION as string,
      },
    },
  },
}

cognitoUserPoolsTokenProvider.setKeyValueStorage(new CookieStorage())

export const init = () => {
  Amplify.configure(amplifyConfig, {
    ssr: true,
  })
}

init()
