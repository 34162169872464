/* eslint-disable unicorn/numeric-separators-style */
import Cookies from 'js-cookie'
import { POSTCODE_COOKIE_KEY } from './constants'

const DEFAULT_POSTCODE = '2000'

export const getPostcodeClient = () => {
  const postcodeCookie = Cookies.get(POSTCODE_COOKIE_KEY)
  if (postcodeCookie) {
    return postcodeCookie
  }
  return DEFAULT_POSTCODE
}

export const setPostcodeClient = (postcode: string) => {
  Cookies.set(POSTCODE_COOKIE_KEY, postcode, {
    expires: 31536000,
  })
}
