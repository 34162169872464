'use client'

import { type StaticRequire } from 'next/dist/shared/lib/get-img-props'
import { isAbsoluteUrl } from 'next/dist/shared/lib/utils'
import Image, {
  type ImageLoaderProps,
  type ImageProps,
  type StaticImageData,
} from 'next/image'
import { useState } from 'react'
import { ErrorBoundary } from 'react-error-boundary'

export const imageToString = (
  image: StaticImageData | StaticRequire | string,
) =>
  typeof image === 'string'
    ? image
    : ((image as StaticImageData)?.src ?? (image as StaticRequire)?.default)

const myImageLoader = ({ src, width, quality }: ImageLoaderProps) => {
  if (isAbsoluteUrl(src)) {
    return `${src}?width=${width}&quality=${quality ?? 75}`
  }

  return `${process.env.NEXT_PUBLIC_ASSET_URL}${src}?width=${width}&quality=${quality ?? 75}`
}

export const ResolvedImage = ({
  src,
  shouldUseRoot = false,
  ...props
}: ImageProps & { shouldUseRoot?: boolean }) => {
  const [imageError, setImageError] = useState<boolean>(
    !!(src == null || (src as string).length === 0 || src === 'null'),
  )

  const isMarketplacerImage =
    typeof src === 'string' && src.startsWith('https://marketplacer.imgix.net')

  if (imageError) {
    return (
      <Image
        {...props}
        src={'/default.webp'}
        alt={props.alt}
        title={props.alt}
        className='object-contain'
      />
    )
  }

  return (
    <ErrorBoundary
      fallback={
        <Image
          {...props}
          src={'/default.webp'}
          alt={props.alt}
          title={props.alt}
          className='object-contain'
        />
      }
    >
      <Image
        style={{ maxHeight: props.height, maxWidth: props.width }}
        {...props}
        alt={props.alt}
        onError={() => {
          setImageError(true)
        }}
        unoptimized={isMarketplacerImage}
        loader={shouldUseRoot ? undefined : myImageLoader}
        src={src}
      />
    </ErrorBoundary>
  )
}
