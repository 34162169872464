import { sha256 } from '@/utils/hash'
import { sendGTMEvent } from '@next/third-parties/google'

interface iSendCustomerDetails {
  userId: string
  email: string
  phone: string
  firstName: string
  lastName: string
  address?: string | null
  city?: string | null
  zip?: string | null
  countryCode?: string | null
}

const formatPhone = (phone: string) => {
  let formattedPhone = phone.replaceAll(/^0+/gim, '')
  formattedPhone = phone.replaceAll(/\D/gim, '')
  return `+61${formattedPhone}`
}

interface iCustomerEventData {
  event: 'customerDetails'
  customer_id: string
  email: string
  em: string
  phone: string
  ph: string
  first_name: string
  fn: string
  last_name: string
  ln: string
  address?: string
  ad?: string
  city?: string
  ct?: string
  zip?: string
  zp?: string
  country_code?: string
  country?: string
}

export const sendCustomerDetailsClient = async (data: iSendCustomerDetails) => {
  const { address, zip, userId } = data

  const email = data.email.toLowerCase()
  const phone = formatPhone(data.phone)
  const firstName = data.firstName.toLowerCase()
  const lastName = data.lastName.toLowerCase()
  const city = data.city?.toLowerCase().replaceAll(/\W+/gim, '')
  const countryCode = data.countryCode?.toLowerCase()

  const [
    emailHash,
    phoneHash,
    firstNameHash,
    lastNameHash,
    addressHash,
    cityHash,
    zipHash,
    countryCodeHash,
  ] = await Promise.all([
    sha256(email),
    sha256(phone),
    sha256(firstName),
    sha256(lastName),
    address !== undefined && address !== null ? sha256(address) : null,
    city !== undefined && city !== null ? sha256(city) : null,
    zip !== undefined && zip !== null ? sha256(zip) : null,
    countryCode ? sha256(countryCode) : null,
  ])

  const eventData: iCustomerEventData = {
    event: 'customerDetails',
    customer_id: userId,
    email,
    em: emailHash,
    phone,
    ph: phoneHash,
    first_name: firstName,
    fn: firstNameHash,
    last_name: lastName,
    ln: lastNameHash,
  }

  if (
    address !== undefined &&
    address !== null &&
    addressHash !== undefined &&
    addressHash !== null
  ) {
    eventData.address = address
    eventData.ad = addressHash
  }

  if (
    city !== undefined &&
    city !== null &&
    cityHash !== undefined &&
    cityHash !== null
  ) {
    eventData.city = city
    eventData.ct = cityHash
  }

  if (
    zip !== undefined &&
    zip !== null &&
    zipHash !== undefined &&
    zipHash !== null
  ) {
    eventData.zip = zip
    eventData.zp = zipHash
  }

  if (
    countryCode !== undefined &&
    countryCode !== null &&
    countryCodeHash !== undefined &&
    countryCodeHash !== null
  ) {
    eventData.country_code = countryCode
    eventData.country = countryCodeHash
  }

  sendGTMEvent(eventData)
}
