import { BASKET_COOKIE_KEY, PLATFORM_BASKET_COOKIE_KEY } from './constants'

import Cookies from 'js-cookie'
import { isNullOrEmpty } from '@/utils/string-extensions'

export const getBasketClient = () => {
  const basketCookie = Cookies.get(BASKET_COOKIE_KEY)

  if (!isNullOrEmpty(basketCookie)) {
    return basketCookie
  }
}

export const setBasketClient = (basket: string) => {
  if (!isNullOrEmpty(basket)) {
    Cookies.set(BASKET_COOKIE_KEY, basket, {
      expires: 31_536_000,
    })
  }
}

export const getPlatformBasketClient = () => {
  const basketCookie = Cookies.get(PLATFORM_BASKET_COOKIE_KEY)

  if (!isNullOrEmpty(basketCookie)) {
    return basketCookie
  }
}

export const setPlatformBasketClient = (basket: string) => {
  if (!isNullOrEmpty(basket)) {
    Cookies.set(PLATFORM_BASKET_COOKIE_KEY, basket, {
      expires: 31_536_000,
    })
  }
}

export const setBasketCookieSession = () => {
  const basketCookie = Cookies.get(BASKET_COOKIE_KEY)

  if (!isNullOrEmpty(basketCookie)) {
    Cookies.set(BASKET_COOKIE_KEY, basketCookie, {
      expires: undefined,
    })
  }

  const platformBasketCookie = Cookies.get(PLATFORM_BASKET_COOKIE_KEY)

  if (!isNullOrEmpty(platformBasketCookie)) {
    Cookies.set(PLATFORM_BASKET_COOKIE_KEY, platformBasketCookie, {
      expires: undefined,
    })
  }
}

export const deleteBasketClient = () => {
  Cookies.set(BASKET_COOKIE_KEY, '', {
    expires: 0,
    path: '/',
  })

  Cookies.set(PLATFORM_BASKET_COOKIE_KEY, '', {
    expires: 0,
    path: '/',
  })
}
